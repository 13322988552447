import { TouchableOpacity, View, Image } from "react-native";
import Modal from "../../../../components/modal";
import Text from "../../../../components/Text";
import tailwind from "twrnc";
import { useState } from "react";
import { TEACHERS, API } from "../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import Alert from "../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";

const RecordingModal = ({ open, setOpen, item }) => {
    const [loading, setLoading] = useState(false);
    const { navigate } = useNavigation();

    const playRecording = async (recording_item, class_type, call) => {
        try {
            navigate("Recording", {
                type: class_type,
                id: call.id,
            })
            setOpen(false)
        } catch (error) {
            setLoading(false);
            Alert.alert("Sorry, could not play recording. Try again");
        }
    };

    return (
        <Modal visible={open} setVisible={setOpen} maxHeight={500}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    <Text style={tailwind`font-bold text-[16px]`}>Recordings</Text>

                    <AntDesign
                        onPress={() => setOpen(false)}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>

                {item.classes &&
                    item.classes
                        .filter((x) => x.recordings[0])
                        .map((call, i) => {
                            const date = moment.unix(call.slot / 1000).format("Do MMMM YYYY");
                            return (
                                <View key={i} style={tailwind``}>
                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                    {call.recordings
                                        .filter((record) => record.file_type === "MP4")
                                        .map((record, record_index) => (
                                            <View
                                                style={tailwind`flex flex-row justify-between mb-5 items-center`}
                                                key={record_index}
                                            >
                                                <View style={tailwind`flex flex-row items-center`}>
                                                    <Image
                                                        style={tailwind`w-[40px] h-[40px]`}
                                                        source={require("../../../../../assets/video_image.png")}
                                                    />
                                                    <Text style={tailwind`w-[180px] text-[12px] ml-3`}>
                                                        {call?.topic}
                                                    </Text>
                                                </View>
                                                <TouchableOpacity disabled={loading}
                                                    onPress={() => playRecording(record, 'WEBINAR', call)}
                                                >
                                                    <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>
                                                        Play
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>
                                        ))}

                                </View>
                            );
                        })}



            </View>
        </Modal>
    );
};

export default RecordingModal;
