import React, { useState, useEffect } from "react";
import { Image, View, ScrollView, Pressable, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../components/Text";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import useUser from "../../../../../../context/user";
import { TEACHERS, API } from "../../../../../../api/instance";
import Alert from "../../../../../../utils/alert";
import useQuiz from "../../../quizzes/common/context";

const AllQuizzes = ({ openQuizTestReportForm }) => {
    const { navigate } = useNavigation();
    const { user } = useUser();
    let [all_quizzes, setAllQuizzes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [user2, setUser2] = useState(null);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [test_report_view, setTestReportView] = useState("no");

    const {
        questions,
        setQuestions,
        setCurrentQuestionIndex,
        setQuiz,
        setUserQuizLogId,
        setTotalQuestion,
        class_id,
        setClassId,
        class_type,
        setClassType,
        setTotalCorrectAnswer,
        setTotalIncorrectAnswer,
        setTotalQuestionAttempt,
        setWqTextAnswer,
        userEmail1,
        setUserEmail1,
        userEmail2,
        setUserEmail2,
        quizTimers,
        setQuizTimers,
        question_time_duration,
        setQuestionTimeDuration
    } = useQuiz();

    useEffect(() => {
        getAllQuizzes()
        getTimers()
    }, []);

    async function getAllQuizzes() {
        let params = {
            "phone": user.phone
        }
        if (user.is_live_teacher)
            params.app_user_id = user._id

        await TEACHERS.post(`/allquizzes`, params)
            .then((response) => {
                let res = response.data.data
                setAllQuizzes(res)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getTimers() {
        const { data } = await TEACHERS.get(
            `/quiztimers`
        );
        setQuizTimers(data.data)
    }

    const handleClick = async (item) => {
        try {
            if (item.logs.length > 3) {
                Alert.alert1("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                setUserQuizLogId("")
                let c_type = ""
                let c_id = ""

                if (item.entity_type == "WEBINAR") {
                    setClassId(item.entity_id)
                    setClassType("WEBINAR")
                    c_type = "WEBINAR"
                    c_id = item.entity_id
                } else if (item.entity_type == "SESSION") {
                    setClassId(item.entity_id)
                    setClassType("SESSION")
                    c_type = "SESSION"
                    c_id = item.entity_id
                } else {
                    setClassId("")
                    setClassType("")
                    c_type = ""
                    c_id = ""
                    Alert.alert("Something went Wrong.Please try again");
                    return false
                }

                const { data } = await TEACHERS.get(
                    `/quiz/${item.quiz.uuid}`
                );

                // const { data } = await TEACHERS.get(
                //     `/quizquestions/${item.quiz.uuid}`
                // );

                let res = (data?.data && data?.data.length > 0) ? data.data : []
                if (res.length > 0) {
                    let log_payload = {
                        "class_type": c_type,
                        "class_id": c_id,
                        "phone": user.phone,
                        "quiz_id": item.quiz_id,
                        "quiz_start_timestamp": moment().format("x"),
                        "total_questions": res.length
                    }

                    let result = await TEACHERS.post(
                        `quiz/logs`, log_payload
                    );

                    let response = result.data.data

                    if (response) {
                        setUser2(response.user)
                        setUserQuizLogId(response.id)
                        setQuestions(res)
                        setQuiz(item.quiz)

                        const q_arr = res.filter(x =>
                            x.type_code != "LC" &&
                            x.type_code != "CVC" &&
                            x.type_code != "LCC"
                        );
                        setTotalQuestion(q_arr.length)
                        setUserEmail1(user.email)
                        setUserEmail2(response.user?.email)

                        console.log("item.test_report : ", item.quiz.test_report)

                        if (item?.quiz?.test_report == "yes") {
                            console.log("inside")
                            openQuizTestReportForm(item, response.user)
                            setLoading(false);
                            setTestReportView(item.quiz.test_report)
                        } else {
                            console.log("outside")
                            startQuiz(res)
                        }
                    }
                }
                else Alert.alert("Could not open Quiz. Please try again");
            }
        } catch (error) {
            setLoading(false);
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert1("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    };

    const startQuiz = async (qns = null) => {
        setLoading(false);
        // setOpen(false);
        setTotalCorrectAnswer(0)
        setTotalIncorrectAnswer(0)
        setTotalQuestionAttempt(0)
        setWqTextAnswer("")
        let q_index = 0

        let curr_qns = null
        if (questions.length > 0) {
            curr_qns = questions[q_index]
        } else if (qns) {
            curr_qns = qns[q_index]
        } else {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
            return
        }

        let obj = quizTimers.find(item => item.question_type == curr_qns.type_code)
        if (!obj) {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
        }

        setCurrentQuestionIndex(q_index)
        setQuestionTimeDuration(obj.timer)
        return navigate("Quizzes");
    };

    const handleRedirect = async () => {
        navigate("AllQuizzes")
    }

    return (
        <View style={tailwind`w-full`}>
            {all_quizzes.total > 0 &&
                <>
                    <View
                        style={tailwind`flex flex-row justify-between w-full items-center`}
                    >
                        <Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
                            All Quizzes
                        </Text>

                        <Text onPress={() => handleRedirect()}
                            style={tailwind`text-[#2096F3] font-semibold`}
                        >
                            View All
                        </Text>
                    </View>

                    <ScrollView
                        horizontal
                        style={{ maxWidth: "100%" }}
                    >

                        {all_quizzes.data.map((item, i) => (
                            <TouchableOpacity
                                onPress={() => handleClick(item)}
                                key={i}>
                                <View style={[
                                    tailwind`mr-2 bg-slate-100 mb-5`,
                                    { height: 200, justifyContent: "space-between" }
                                ]} >

                                    <View>
                                        <Image
                                            style={tailwind`w-[200px] h-[100px] px-4`}
                                            source={{ uri: item?.quiz?.course?.image }}
                                        />
                                        <Text
                                            style={[tailwind`capitalize text-blue-500 w-[200px] px-2 text-[12px] mt-2 font-semibold`]}
                                        >
                                            {/* {item.quiz.name} - {item.entity_type}-{item.call.id} */}
                                            {(item.entity_type == "SESSION") ? item.call.topic_name : item.call.topic}

                                            {/* - quiz id {item.quiz_id}- {item.entity_type}-{item.entity_id} */}
                                        </Text>

                                        <Text
                                            style={[tailwind`capitalize text-slate-500 w-[200px] px-2 text-[12px] mt-2 font-semibold`]}
                                        >
                                            {item.quiz.questions.length} Questions
                                        </Text>

                                        <Text
                                            style={[tailwind`capitalize text-slate-500 w-[200px] px-2 text-[12px]  mt-2 font-semibold`]}
                                        >
                                            {item.logs.length} Attempts; Last Best Score: {item.logs.length > 0
                                                ? Math.max(...item.logs.map(log =>
                                                    ((log.total_correct_answer / item.quiz.questions.length) * 100).toFixed(2)

                                                )) + "%"
                                                : "No scores yet"}
                                        </Text>

                                    </View>
                                </View>
                            </TouchableOpacity>
                        ))}
                    </ScrollView>
                </>
            }
        </View >
    );

};

export default AllQuizzes;
