import { TouchableOpacity, View, ActivityIndicator, Pressable, StyleSheet, TextInput } from "react-native";
import Modal from "../../../../components/modal";
import Text from "../../../../components/Text";
import tailwind from "twrnc";
import { useState, useEffect } from "react";
import { TEACHERS, API } from "../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import Alert from "../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";
import useQuiz from "./common/context";
import useUser from "../../../../context/user";

const TestReportDialog = ({ open, setOpen, item = null, user2 = null }) => {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [submmiting, setSubmmiting] = useState(false);

    const {
        questions,
        setQuestions,
        setCurrentQuestionIndex,
        setQuiz,
        setUserQuizLogId,
        setTotalQuestion,
        class_id,
        setClassId,
        class_type,
        setClassType,
        setTotalCorrectAnswer,
        setTotalIncorrectAnswer,
        setTotalQuestionAttempt,
        setWqTextAnswer,
        userEmail1,
        setUserEmail1,
        userEmail2,
        setUserEmail2,
        quizTimers,
        setQuizTimers,
        question_time_duration,
        setQuestionTimeDuration
    } = useQuiz();

    const handleEmailChange = (email) => {
        setEmail(email);

        // Validate email and set an error message if invalid
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError(""); // Clear the error message if valid
        }
    };

    const handleModalClose = async () => {
        setOpen(false)
    }

    const startQuiz = async (qns = null) => {
        setLoading(false);
        setOpen(false);
        setTotalCorrectAnswer(0)
        setTotalIncorrectAnswer(0)
        setTotalQuestionAttempt(0)
        setWqTextAnswer("")
        let q_index = 0

        let curr_qns = null
        if (questions.length > 0) {
            curr_qns = questions[q_index]
        } else if (qns) {
            curr_qns = qns[q_index]
        } else {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
            return
        }

        let obj = quizTimers.find(item => item.question_type == curr_qns.type_code)
        if (!obj) {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
        }

        setCurrentQuestionIndex(q_index)
        setQuestionTimeDuration(obj.timer)
        return navigate("Quizzes");
    };


    const submit = async () => {
        let payload = {
            email: email,
        };
        try {
            setSubmmiting(true);

            setUserEmail1(email)
            setUserEmail2(email)

            startQuiz()

            await API.put(`/user/${user._id}`, payload)
                .then(response => {
                    setSubmmiting(false);
                })
                .catch(error => {
                    console.log("update profile error : ", error.response.data)
                    Alert.success("Something went wrong");
                })

        } catch (error) {
            setSubmmiting(false);
            console.log(error)
        }
    }

    return (
        <Modal visible={open} setVisible={setOpen} maxHeight={500}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>


                    {(user.email || user2.email) &&
                        <Text style={tailwind`font-bold text-[16px]`}>Email Address For Test Report</Text>
                    }

                    {(!user.email && !user2.email) &&
                        <Text style={tailwind`font-bold text-[16px]`}>Share Your Email Address For Test Report</Text>
                    }

                    <AntDesign
                        onPress={() => handleModalClose()}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>
                <View>
                    {(user.email || user2?.email) &&
                        <View>
                            {(user.email == user2?.email) &&
                                <Text style={tailwind`p-3  font-semibold text-center`}>
                                    {`We will send your test report on your email id ${user.email} at the end of this test. Please write to us at support@multibhashi.com in case you have any doubts.`}
                                </Text>
                            }

                            {(user.email != user2?.email && user.email && user2?.email) &&
                                <Text style={tailwind`p-3  font-semibold text-center`}>
                                    {`We will send your test report on your email id ${user.email} and ${user2.email} at the end of this test. Please write to us at support@multibhashi.com in case you have any doubts`}
                                </Text>
                            }

                            {(user.email != user2?.email && (user.email || user2?.email)) &&
                                <Text style={tailwind`p-3  font-semibold text-center`}>
                                    {`We will send your test report on your email id ${(user.email) ? user.email : user2?.email} at the end of this test. Please write to us at support@multibhashi.com in case you have any doubts`}
                                </Text>
                            }

                            <View style={tailwind``}>
                                <TouchableOpacity
                                    style={[
                                        styles.buttonStyle,
                                        { backgroundColor: "#6b7280" },
                                    ]}
                                    onPress={() => startQuiz()}
                                >
                                    <Text style={styles.buttonText}> Start </Text>
                                </TouchableOpacity>
                            </View>

                        </View>
                    }

                    {
                        (!user.email && !user2.email) &&
                        <View>
                            <View style={styles.mainContainer}>

                                <Text style={tailwind`p-3  font-semibold text-justify`}>
                                    We have to send your test report on your email at the end of this test so please provide your email address. Please write to us at support@multibhashi.com in case you have any doubts
                                </Text>

                                <View style={styles.inputContainer}>
                                    <Text style={styles.labels}>Email </Text>
                                    <TextInput
                                        style={[styles.inputStyle, { color: "black" }]}
                                        placeholder={"Email address"}
                                        value={email}
                                        onChangeText={handleEmailChange}
                                    />
                                </View>

                                {!submmiting && (
                                    <TouchableOpacity
                                        style={[
                                            styles.buttonStyle,
                                            { backgroundColor: "#6b7280" },
                                        ]}
                                        disabled={emailError !== "" || email === ""}
                                        onPress={submit}
                                    >
                                        <Text style={styles.buttonText}> Submit </Text>
                                    </TouchableOpacity>
                                )}
                                {emailError ? <Text style={styles.errorText}>{emailError}</Text> : null}


                                {submmiting && (
                                    <TouchableOpacity
                                        style={[styles.buttonStyle, { backgroundColor: "#22c55e" }]}
                                        onPress={null}
                                    >
                                        <Text style={styles.buttonText}> Loading </Text>
                                    </TouchableOpacity>
                                )}
                            </View>

                        </View>
                    }
                </View>
            </View>
        </Modal >
    );
};

export default TestReportDialog;


const styles = StyleSheet.create({
    mainContainer: {
        // height: "100%",
        paddingHorizontal: 20,
        backgroundColor: "#fff",
    },

    inputContainer: {
        marginTop: 10,
    },
    labels: {
        fontWeight: "bold",
        // fontSize: 15,
        color: "#7d7d7d",
        paddingBottom: 5,
        lineHeight: 25,
    },
    inputStyle: {
        // borderWidth: 1,
        borderBottomWidth: 1.4,
        borderColor: "rgba(0, 0, 0, 0.3)",
        paddingHorizontal: 10,
        paddingVertical: 6,
        borderRadius: 2,
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        color: "#fff",
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50%",
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    errorText: {
        color: "red",
        fontSize: 12,
        marginBottom: 10,
    },
});
