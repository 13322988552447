import React, { useState, useEffect } from "react";
import { Image, View, ScrollView, Pressable, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import useUser from "../../../../../context/user";
import { TEACHERS, API } from "../../../../../api/instance";

import RecordingModal from "../../recording/recording_modal";

const RecordingsSlider = () => {
    const { navigate } = useNavigation();
    const { user } = useUser();

    let [recordings, setRecordings] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    let [recording_item, setRecordingItem] = useState(null);


    useEffect(() => {
        getRecordings()
    }, []);

    async function getRecordings() {
        let params = {
            "phone": user.phone
        }
        if (user.is_live_teacher)
            params.app_user_id = user._id

        await TEACHERS.post(`/allrecordings`, params)
            .then((response) => {
                let res = response.data.data
                setRecordings(res)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleRedirect = async () => {
        navigate("AllRecordings")
    };

    const handleClick = async (item) => {
        setRecordingItem(item)
        if ((item.calls && item.calls.length > 0) || (item.classes && item.classes.length > 0)) {
            setModalOpen(true)
        }
    };

    return (
        <View style={tailwind`w-full`}>
            {
                recordings.length > 0 &&
                <>
                    <View
                        style={tailwind`flex flex-row justify-between w-full items-center`}
                    >
                        <Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
                            All Recordings
                        </Text>

                        <Text
                            onPress={() => handleRedirect()}
                            style={tailwind`text-[#2096F3] font-semibold`}
                        >
                            View All
                        </Text>
                    </View>

                    <ScrollView
                        horizontal
                        style={{ maxWidth: "100%" }}
                    >

                        {
                            recording_item && <RecordingModal
                                open={modalOpen}
                                setOpen={setModalOpen}
                                item={recording_item}
                            />
                        }

                        {recordings.map((item, i) => (
                            <View style={[
                                tailwind`mr-2 bg-slate-100 mb-5`,
                                { height: 180, justifyContent: "space-between" }]}
                                key={i}
                            >
                                <View>
                                    <View style={tailwind`items-center`}>
                                        {item.course && <Image
                                            style={tailwind`w-[210px] h-[100px] px-4`}
                                            source={{ uri: item?.course?.image }}
                                        />}

                                        {!item.course && <Image
                                            style={tailwind`w-[100px] h-[100px] px-4`}
                                            source={require("../../../../../../assets/logo_with_shadow.png")}
                                        />}

                                    </View>

                                    {(item.duration && item.name) &&
                                        <View style={tailwind`mt-1 items-center px-1`}>
                                            <Text style={tailwind`text-[12px] text-blue-500 font-semibold text-center`}>
                                                {item.name}
                                            </Text>
                                        </View>
                                    }

                                    <Text style={tailwind`mt-1 px-1 text-[12px] text-slate-500 text-center font-semibold`}>
                                        Recordings on {item.total_session_recordings} Topics
                                    </Text>

                                    <View style={tailwind`mt-1 flex-row items-center justify-between px-2`}>
                                        <Text style={tailwind`text-[12px] text-slate-500 `}>
                                            {(item.duration) ? "Group Classes" : "1 to 1 Classes"}
                                        </Text>

                                        <TouchableOpacity
                                            onPress={() => handleClick(item)}

                                            style={tailwind``}
                                        >
                                            <Text
                                                style={tailwind`text-center text-blue-600 underline text-[11px]`}
                                            >
                                                View All
                                            </Text>
                                        </TouchableOpacity>
                                    </View>

                                    {/* <View style={tailwind`mt-2 flex-row items-center justify-between px-2`}>
                                            <View style={tailwind`flex-row items-center`}>
                                                <Image
                                                    style={tailwind`w-[30px] h-[30px] rounded-full`}
                                                    source={{ uri: image2 }}
                                                />

                                                <Text style={tailwind`capitalize text-[11px] font-semibold ml-1`}>
                                                    Ashwini J
                                                </Text>
                                            </View>

                                            <Text style={tailwind`px-1 py-0.5 text-[11px] text-white rounded-full bg-blue-500`}>
                                                {(item.duration) ? "Group Classes" : "1 to 1 Classes"}
                                            </Text>
                                        </View> */}

                                    {/* <View style={tailwind`mt-2 px-2 flex-row items-center`}>
                                            <Image
                                                style={tailwind`w-[30px] h-[30px] rounded-full`}
                                                source={{ uri: image2 }}
                                            />

                                            <Text style={tailwind`capitalize text-[12px] font-semibold ml-2`}>
                                                Ashwini J
                                            </Text>
                                        </View> */}

                                    {/* <View style={tailwind`h-[1px] bg-gray-300 px-1 my-1 mx-2`} />

                                        <View style={tailwind` px-2 flex-row items-center`}>
                                            <Text style={tailwind`capitalize text-[14px] text-blue-400 font-semibold`}>
                                                Topic : Short Selt Introduction
                                            </Text>
                                        </View> */}

                                </View>
                            </View>
                        ))}


                    </ScrollView>
                </>
            }
        </View >
    );

};

export default RecordingsSlider;
