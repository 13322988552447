import {
    View,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    ActivityIndicator
} from "react-native";
import Modal from "../../../../components/modal";
import Text from "../../../../components/Text";
import tailwind from "twrnc";
import { useState, useEffect } from "react";
import { TEACHERS, API } from "../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import Alert from "../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";
import useQuiz from "./common/context";
import useUser from "../../../../context/user";
import { height, isWeb, width } from "../../../../constants/constants";
let wd = width <= 500 ? width : 499.99
import Layout from "../../layout";
import PageHeader from "../../headers/page.header";
import TestReportDialog from "./test_report_dialog";

export default function AllQuizzes() {
    const { user } = useUser();
    const { navigate } = useNavigation();
    const [loading, setLoading] = useState(false);
    const [all_quizzes, setAllQuizzes] = useState([]);
    const [user2, setUser2] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    let [openQuizTestReportForm, setOpenQuizTestReportForm] = useState(null);

    const {
        questions,
        setQuestions,
        setCurrentQuestionIndex,
        setQuiz,
        setUserQuizLogId,
        setTotalQuestion,
        class_id,
        setClassId,
        class_type,
        setClassType,
        setTotalCorrectAnswer,
        setTotalIncorrectAnswer,
        setTotalQuestionAttempt,
        setWqTextAnswer,
        userEmail1,
        setUserEmail1,
        userEmail2,
        setUserEmail2,
        quizTimers,
        setQuizTimers,
        question_time_duration,
        setQuestionTimeDuration
    } = useQuiz();

    useEffect(() => {
        getAllQuizzes()
    }, []);

    async function getAllQuizzes() {
        let params = {
            "phone": user.phone,
            "limit": 500
        }
        if (user.is_live_teacher)
            params.app_user_id = user._id

        setLoading(true)

        await TEACHERS.post(`/allquizzes`, params)
            .then((response) => {
                let res = response.data.data
                setAllQuizzes(res)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }

    const getImageSource = (item) => {
        return item?.quiz?.course?.image
    };

    const handleClick = async (item) => {
        try {
            if (item.logs.length > 3) {
                Alert.alert1("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                setUserQuizLogId("")
                let c_type = ""
                let c_id = ""

                if (item.entity_type == "WEBINAR") {
                    setClassId(item.entity_id)
                    setClassType("WEBINAR")
                    c_type = "WEBINAR"
                    c_id = item.entity_id
                } else if (item.entity_type == "SESSION") {
                    setClassId(item.entity_id)
                    setClassType("SESSION")
                    c_type = "SESSION"
                    c_id = item.entity_id
                } else {
                    setClassId("")
                    setClassType("")
                    c_type = ""
                    c_id = ""
                    Alert.alert("Something went Wrong.Please try again");
                    return false
                }

                const { data } = await TEACHERS.get(
                    `/quiz/${item.quiz.uuid}`
                );

                // const { data } = await TEACHERS.get(
                //     `/quizquestions/${item.quiz.uuid}`
                // );

                let res = (data?.data && data?.data.length > 0) ? data.data : []
                if (res.length > 0) {
                    let log_payload = {
                        "class_type": c_type,
                        "class_id": c_id,
                        "phone": user.phone,
                        "quiz_id": item.quiz_id,
                        "quiz_start_timestamp": moment().format("x"),
                        "total_questions": res.length
                    }

                    let result = await TEACHERS.post(
                        `quiz/logs`, log_payload
                    );

                    let response = result.data.data

                    if (response) {
                        setUser2(response.user)
                        setUserQuizLogId(response.id)
                        setQuestions(res)
                        setQuiz(item.quiz)

                        const q_arr = res.filter(x =>
                            x.type_code != "LC" &&
                            x.type_code != "CVC" &&
                            x.type_code != "LCC"
                        );
                        setTotalQuestion(q_arr.length)
                        setUserEmail1(user.email)
                        setUserEmail2(response.user?.email)

                        if (item?.quiz.test_report == "yes") {
                            setLoading(false);
                            setOpenQuizTestReportForm(item)
                            setModalOpen(true)

                        } else {
                            startQuiz(res)
                        }
                    }
                }
                else Alert.alert("Could not open Quiz. Please try again");
            }
        } catch (error) {
            setLoading(false);
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert1("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    };

    const startQuiz = async (qns = null) => {
        setLoading(false);
        // setOpen(false);
        setTotalCorrectAnswer(0)
        setTotalIncorrectAnswer(0)
        setTotalQuestionAttempt(0)
        setWqTextAnswer("")
        let q_index = 0

        let curr_qns = null
        if (questions.length > 0) {
            curr_qns = questions[q_index]
        } else if (qns) {
            curr_qns = qns[q_index]
        } else {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
            return
        }

        let obj = quizTimers.find(item => item.question_type == curr_qns.type_code)
        if (!obj) {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
        }

        setCurrentQuestionIndex(q_index)
        setQuestionTimeDuration(obj.timer)
        return navigate("Quizzes");
    };

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute="Home" pageTitle="All Quizzes" />
            }
        // scrollView={false}
        >
            <View style={tailwind`flex flex-row w-full mt-4`}>

                <View style={styles.container}>
                    {
                        !loading && (<>

                            {
                                openQuizTestReportForm && <TestReportDialog
                                    open={modalOpen}
                                    setOpen={setModalOpen}
                                    item={openQuizTestReportForm}
                                    user2={user2}
                                />
                            }

                            <FlatList
                                data={all_quizzes.data}
                                numColumns={3}
                                contentContainerStyle={styles.listContainer}
                                columnWrapperStyle={styles.columnWrapper}
                                renderItem={({ item }) => (
                                    <View>
                                        <Pressable style={styles.card}
                                            onPress={() => handleClick(item)}>

                                            <Image
                                                source={{ uri: getImageSource(item) }}
                                                style={styles.image}
                                                resizeMode="contain"
                                            />

                                            <Text
                                                style={[tailwind`capitalize text-blue-500 px-2 text-[12px] mt-2 font-semibold`]}
                                            >
                                                {/* {item.quiz.name} - {item.entity_type}-{item.call.id} */}
                                                {(item.entity_type == "SESSION") ? item.call.topic_name : item.call.topic}

                                                {/* - quiz id {item.quiz_id}- {item.entity_type}-{item.entity_id} */}
                                            </Text>

                                            <Text
                                                style={[tailwind`capitalize text-slate-500 px-2 text-[12px] mt-2 font-semibold`]}
                                            >
                                                {item.quiz.questions.length} Questions
                                            </Text>

                                            <Text
                                                style={[tailwind`capitalize text-slate-500  px-2 text-[12px]  mt-2 font-semibold`]}
                                            >
                                                {item.logs.length} Attempts; Last Best Score: {item.logs.length > 0
                                                    ? Math.max(...item.logs.map(log =>
                                                        ((log.total_correct_answer / item.quiz.questions.length) * 100).toFixed(2)

                                                    )) + "%"
                                                    : "No scores yet"}
                                            </Text>
                                        </Pressable>
                                    </View>
                                )}
                                keyExtractor={(item) => item.id.toString()}
                            />
                        </>)}

                    {loading &&
                        <View style={[tailwind`flex-1 bg-white-500`]}>
                            <View style={[tailwind`flex-1 justify-center items-center`]}>
                                <ActivityIndicator size="large" color="#60a5fa" />
                            </View>
                        </View>
                    }

                </View>



            </View>
        </Layout>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // padding: 10,
        backgroundColor: '#f8f8f8',
    },
    listContainer: {
        paddingBottom: 20,
    },
    columnWrapper: {
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },

    card: {
        backgroundColor: '#ffffff',
        margin: 5,
        // padding: 5,
        width: (wd - 70) / 3, // Adjust the width to ensure three columns
        height: 250,  // Fixed height for all cards
        justifyContent: 'center',
        alignItems: 'center',
        // borderRadius: 8,
        elevation: 3, // For shadow on Android
        shadowColor: '#000', // For shadow on iOS
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        // shadowRadius: 4,
    },
    image: {
        width: '100%',
        height: 80, // Adjust height as needed for better fit
        minHeight: 80
    },
    title: {
        padding: 5,
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#333',
        minHeight: 50
    },

});
