import * as React from "react";
import { View, Button, Platform, Text } from "react-native";
import { Video, ResizeMode } from "expo-av";
import tailwind from "twrnc";
import { width } from "../../../../constants/constants";
import ReactPlayer from "react-player";

export default function RecordingPlayer({ recordings }) {
	const video = React.useRef(null);

	const [selectedRecordingIndex, setSelectedRecordingIndex] = React.useState(0);

	return (
		<View style={tailwind` mt-5`}>
			{Platform.OS === "web" ? (
				<ReactPlayer
					url={recordings[selectedRecordingIndex]}
					controls
					ref={video}
				/>
			) : (
				<Video
					ref={video}
					style={tailwind`w-[${width > 500 ? "500" : width - 50}px] h-[200px]`}
					source={{
						uri: recordings[selectedRecordingIndex],
					}}
					useNativeControls
					resizeMode={ResizeMode.CONTAIN}
					isLooping
				/>
			)}

			<View style={tailwind`flex flex-col`}>
				{recordings.map((recording, i) => (
					// <Button
					// 	style={tailwind`mb-5`}
					// 	key={i}
					// 	title={`Play Recording ${i + 1} `}
					// 	onPress={() => {
					// 		setSelectedRecordingIndex(i);
					// 		video.current.playAsync();
					// 	}}
					// />
					<View key={i}>
						<Button
							style={tailwind`mb-5`}
							key={i}
							title={`Play Recording ${i + 1} `}
							onPress={() => {
								setSelectedRecordingIndex(i);
								video.current.playAsync();
							}}
						/>
					</View>
				))}
			</View>
		</View>
	);
}
