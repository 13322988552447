import Modal from "../../../../../components/modal";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { View, TouchableOpacity, TextInput, StyleSheet, FlatList, ActivityIndicator } from "react-native";
import useClassesFilter from "./context";
import { height, isWeb } from "../../../../../constants/constants";
import { AntDesign } from "@expo/vector-icons";
import moment from "moment";
import { TEACHERS } from "../../../../../api/instance";
import { useState, useEffect } from "react";
import { SelectList } from "react-native-dropdown-select-list";
import useUser from "../../../../../context/user";
import Alert from "../../../../../utils/alert";
import { RadioButton } from "react-native-paper";

const UpdateStatusModal = ({ modalOpen, setModalOpen, call, getCalls }) => {
    let { user } = useUser();

    let call_statuses = []

    let class_type = ""
    let topic = ""
    if (call.webinar_id) {
        class_type = "WEBINAR"
        topic = call.topic

        let duration = (+call.webinar.duration - 1)
        let end_slot = moment(call.slot, 'x').add(duration, "m").format("x")

        if (+end_slot < moment().format('x')) {
            call_statuses.push({
                key: "Complete",
                value: "complete"
            })
        }

        call_statuses.push({
            key: "Canceled",
            value: "canceled"
        })
    }

    const [users, setUsers] = useState((class_type == "WEBINAR") ?
        call.users.map((item) => ({ ...item, checked: false })) : []);

    if (call.session_id) {
        class_type = "SESSION"
        topic = call.topic_name

        let end_slot = moment(call.slot, 'x').add(29, "m").format("x")

        if (+end_slot < moment().format('x')) {
            call_statuses.push({
                key: "Complete",
                value: "complete"
            })
        }

        call_statuses.push({
            key: "Rescheduled",
            value: "reschedule"
        })
    }

    const [topics, setTopics] = useState([]);
    // const [call_statuses, setCallStatuses] = useState([
    //     {
    //         key: "Complete",
    //         value: "complete"
    //     }
    // ]);
    const [selected_status, setSelectedStatus] = useState(null);
    const [student_attended, setStudentAttended] = useState(0);
    const [selected_topic, setSelectedTopic] = useState(null);
    const [topic_name, setTopicName] = useState("");
    const [topic_change_reasons, setTopicChangeReasons] = useState(
        [
            {
                key: 1,
                value: "On student request"
            },
            {
                key: 2,
                value: "Last topic was not competed"
            }
            ,
            {
                key: 3,
                value: "Current topic was already completed in last class"
            },
            {
                key: 4,
                value: "Not able to open the assegned topic"
            },
            {
                key: 5,
                value: "Wrong notes were assigned"
            },
            {
                key: 6,
                value: "Others"
            }
        ]
    );
    const [selected_topic_change, setSelectedTopicChange] = useState(null);
    const [topic_change_reason_text, setTopicChangeReasonText] = useState("");
    const [remarks, setRemarks] = useState("");
    const [isChecked, setChecked] = useState(true);

    const [submit_loading, setSubmitLoading] = useState(false);

    let web_rescheduled_reasons = [
        {
            key: "Network issues Trainer",
            value: "Network issues Trainer"
        },
        {
            key: "Personal Reason",
            value: "Personal Reason"
        },
        {
            key: "Medical reason",
            value: "Medical reason"
        }
    ]

    const [ses_rescheduled_reasons, setSesRescheduledReasons] = useState([]);

    const [selected_rescheduled_reason, setSelectedRescheduledReason] = useState(null);
    const [rescheduled_by, setRescheduledBy] = useState(null);
    const [notification, setNotification] = useState("");

    const [limit_checked, setLimitChecked] = useState(false);
    const [check_skip_res, setCheckSkipRes] = useState(null);
    const [class_rescheduled, setClassRescheduled] = useState(false);
    const [skip_loading1, setSkipLoading1] = useState(false);
    const [skip_loading, setSkipLoading] = useState(false);

    useEffect(() => {
        if (modalOpen == true) {
            getTopics()
        }
    }, [modalOpen]);

    async function getTopics() {
        let params = {
            class_type: class_type,
            class_id: (class_type == "WEBINAR") ? call.webinar_id : call.session_id
        }

        await TEACHERS.post(`/classes/topics`, params)
            .then((response) => {
                let res = response.data.data

                if (class_type == "WEBINAR") {
                    res.push({
                        "topic": "Others"
                    })
                }

                if (class_type == "SESSION") {
                    res.push({
                        "topic_name": "Others"
                    })
                }

                if (res.length > 0) {
                    let dropdownData = res.map((item, index) => ({
                        key: index.toString(),
                        value: (class_type == "WEBINAR") ? item.topic : item.topic_name,
                    }));
                    setTopics(dropdownData)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleModalClose = async () => {
        setModalOpen(false)
    }

    const toggleCheckbox = (id) => {
        setUsers((prevList) =>
            prevList.map((item) =>
                item.id === id ? { ...item, checked: !item.checked } : item
            )
        );
    };

    const toggleSelectAll = () => {
        const allChecked = users.every((item) => item.checked);
        setUsers((prevList) =>
            prevList.map((item) => ({ ...item, checked: !allChecked }))
        );
    };

    const allChecked = users.every((item) => item.checked);

    const submitOld = async () => {
        let now = moment().format('x')
        let update_time;

        if (!selected_status || selected_status == "") {
            Alert.alert("Please select the status.");
            return false;
        }

        if (selected_status == "complete") {
            if (class_type == "WEBINAR") {
                update_time = +moment(call.slot, 'x').add('m', +call.webinar.duration).format('x')
            } else {
                update_time = +moment(call.slot, 'x').add('m', 30).format('x')
            }

            if (+update_time > +now) {
                Alert.alert("Cannot update class in advance.");
                return false;
            }

            if (!selected_topic || selected_topic == '') {
                Alert.alert("Select topic name.");
                return false;
            }

            if (selected_topic && selected_topic != '' && selected_topic != topic && !selected_topic_change) {
                Alert.alert("Select reason for change of topic option.");
                return false;
            }
        }


        if (selected_status == "canceled" || selected_status == "reschedule") {
            if (selected_status == "reschedule" && !rescheduled_by) {
                Alert.alert("Please select Rescheduled By.");
                return false;
            }

            if (!selected_rescheduled_reason) {
                Alert.alert("Please select Rescheduled Reason.");
                return false;
            }

            if (!notification) {
                Alert.alert("Please select notification option.");
                return false;
            }
        }

        let params = {
            class_type: class_type,
            class_id: call.id,
            selected_status: selected_status,
            remarks: (remarks) ? remarks : "",
            app_user_id: user._id
        }

        if (selected_status == "complete") {
            params.topic_name = topic
            params.student_attended = student_attended
            params.selected_topic = selected_topic
            params.topic_covered_name = (topic_name) ? topic_name : ""
            params.selected_topic_change = (selected_topic_change) ? selected_topic_change : ""
            params.topic_change_reason_text = (topic_change_reason_text) ? topic_change_reason_text : ""
        }

        if (selected_status == "canceled") {
            params.rescheduled_option = "true"
            params.selected_cancel_option = "cancel_this_class"

            let rescheduled_status = ""
            let reschedule_rate = 0

            if (selected_rescheduled_reason) {
                if (selected_rescheduled_reason == "Network issues Trainer") {
                    rescheduled_status = "trainer_skip"
                    reschedule_rate = 0.5
                }

                if (selected_rescheduled_reason == "Personal Reason") {
                    if (call.slot > now) {
                        rescheduled_status = "trainer_skip"
                        eschedule_rate = 0.5
                    }
                    if (call.slot < now) {
                        rescheduled_status = "trainer_no_show"
                        reschedule_rate = 0.5
                    }
                }

                if (selected_rescheduled_reason == "Medical reason") {
                    if (call.slot > now) {
                        rescheduled_status = "trainer_skip"
                        reschedule_rate = 0.5
                    }
                    if (call.slot < now) {
                        rescheduled_status = "trainer_no_show"
                        reschedule_rate = 0.5
                    }
                }
            }

            params.rescheduled_status = rescheduled_status
            params.reschedule_rate = reschedule_rate
            params.rescheduled_reason = selected_rescheduled_reason
            params.notification = (notification) ? notification : 'no';

        }

        if (selected_status == "reschedule") {
            let new_reschedule_status = ""
            let rescheduled_status = ""
            let reschedule_rate = 0
            if (selected_rescheduled_reason) {
                if (rescheduled_by == "trainee") {
                    if (selected_rescheduled_reason == "Network issues Trainee") {
                        new_reschedule_status = "trainee_skip"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "No show") {
                        new_reschedule_status = "trainee_no_show"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "Prior Info") {
                        new_reschedule_status = "trainee_skip"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "Curriculum Issues") {
                        new_reschedule_status = "other_reschedule"
                        reschedule_rate = 0.5
                    }
                }

                if (rescheduled_by == "trainer") {
                    if (selected_rescheduled_reason == "No show") {
                        new_reschedule_status = "trainer_no_show"
                        reschedule_rate = 0
                    }

                    if (selected_rescheduled_reason == "Prior Info") {
                        new_reschedule_status = "trainer_skip"
                        reschedule_rate = 0
                    }

                    if (selected_rescheduled_reason == "Network issues Trainer") {
                        new_reschedule_status = "trainer_skip"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "Personal Reason") {
                        if (call.slot > now) {
                            new_reschedule_status = "trainer_skip"
                            reschedule_rate = 0.5
                        }
                        if (call.slot < now) {
                            new_reschedule_status = "trainer_no_show"
                            reschedule_rate = 0.5
                        }
                    }

                    if (selected_rescheduled_reason == "Medical reason") {
                        if (call.slot > now) {
                            new_reschedule_status = "trainer_skip"
                            reschedule_rate = 0.5
                        }
                        if (call.slot < now) {
                            new_reschedule_status = "trainer_no_show"
                            reschedule_rate = 0.5
                        }
                    }
                }
            }

            if (rescheduled_by == "trainee" && selected_rescheduled_reason == "No show") {
                params.status_slug = "lapsed"
                params.selected_status = "lapsed"
                params.rescheduled_reason = "Trainee No show";
                params.notification = (notification) ? notification : 'no';

                params.real_status = "reschedule"
                params.status_change_reason = "automatic"
                params.rescheduled_by = rescheduled_by

            } else {
                params.rescheduled_by = rescheduled_by;
                params.rescheduled_reason = selected_rescheduled_reason;
                params.notification = (notification) ? notification : 'no';
                params.rescheduled_status = new_reschedule_status
                params.reschedule_rate = reschedule_rate
            }
        }

        if (class_type == "WEBINAR" && selected_status == "complete") {
            let ids = []
            for (let index = 0; index < users.length; index++) {
                let item = users[index]
                if (item.checked) {
                    ids.push(item.id)
                }
            }
            params.attended_users = ids
        }

        setSubmitLoading(true)
        await TEACHERS.post(`/webapp/status/update`, params)
            .then((response) => {
                setSubmitLoading(false)
                getCalls()
                setModalOpen(false)
                Alert.success("Status Updated Successfully");
                return false
            })
            .catch((error) => {
                console.log(error);
                setSubmitLoading(false)
                Alert.alert("Something Went Wrong.");
                return false

            });

    };

    const submit = async () => {
        let now = moment().format('x')
        let update_time;

        if (!selected_status || selected_status == "") {
            Alert.alert("Please select the status.");
            return false;
        }

        if (selected_status == "complete") {
            if (class_type == "WEBINAR") {
                update_time = +moment(call.slot, 'x').add('m', +call.webinar.duration).format('x')
            } else {
                update_time = +moment(call.slot, 'x').add('m', 30).format('x')
            }

            if (+update_time > +now) {
                Alert.alert("Cannot update class in advance.");
                return false;
            }

            if (!selected_topic || selected_topic == '') {
                Alert.alert("Select topic name.");
                return false;
            }

            if (selected_topic && selected_topic != '' && selected_topic != topic && !selected_topic_change) {
                Alert.alert("Select reason for change of topic option.");
                return false;
            }
        }


        if (selected_status == "canceled" || selected_status == "reschedule") {
            if (selected_status == "reschedule" && !rescheduled_by) {
                Alert.alert("Please select Rescheduled By.");
                return false;
            }

            if (!selected_rescheduled_reason) {
                Alert.alert("Please select Rescheduled Reason.");
                return false;
            }

            if (!notification) {
                Alert.alert("Please select notification option.");
                return false;
            }
        }

        if (selected_status == "reschedule") {
            await checkSkipLimit()
        } else {
            await updateStatus()
        }
    };

    const handleRescheduledBySelect = async (item) => {
        setSelectedRescheduledReason(null)
        setSesRescheduledReasons([])
        let items = []
        if (item == "trainee") {
            items.push({
                key: "Prior Info",
                value: "Prior Info"
            }, {
                key: "Network issues Trainee",
                value: "Network issues Trainee"
            }, {
                key: "Curriculum Issues",
                value: "Curriculum Issues"
            })

            if (call.slot < moment().subtract(24, "hours").format('x')) {
                items.push({
                    key: "No show",
                    value: "No show"
                })
            }
        }

        if (item == "trainer") {
            items.push({
                key: "Network issues Trainer",
                value: "Network issues Trainer"
            }, {
                key: "Personal Reason",
                value: "Personal Reason"
            }, {
                key: "Medical reason",
                value: "Medical reason"
            })
        }
        setSesRescheduledReasons(items)
        setRescheduledBy(item)
    };

    useEffect(() => {
        if (rescheduled_by) {
            setSelectedRescheduledReason(null); // Reset selected reason
        }
    }, [rescheduled_by]);


    let checkSkipLimit = async () => {
        try {
            let type = ""

            if (call.session_id) {
                type = "SESSION"
            } else {
                type = "WEBINAR"
            }
            setSkipLoading1(true)
            setCheckSkipRes(null)
            let params = {
                requested_by: user.is_live_teacher ? "TEACHER" : "USER",
                class_id: call.id,
                class_type: type,
                app_user_id: user._id
            }

            setLimitChecked(true)

            await TEACHERS.post("/classes/skiplimit/check", params)
                .then(response => {
                    let res = response.data.data
                    setCheckSkipRes(res)
                    setSkipLoading1(false)

                })
                .catch(error => {
                    console.log(error)
                })

        } catch (error) {
            console.log(error);
            Alert.alert("Could not skip class. Please contact Support");
        }
    };

    let handleRescheduleConfirm = async (action) => {
        setSkipLoading1(false)
        if (action == "no") {
            setLimitChecked(false)

        } else {
            setSkipLoading(true)
            await updateStatus()
        }
    }

    const updateStatus = async () => {
        let now = moment().format('x')
        let params = {
            class_type: class_type,
            class_id: call.id,
            selected_status: selected_status,
            remarks: (remarks) ? remarks : "",
            app_user_id: user._id
        }

        if (selected_status == "complete") {
            params.topic_name = topic
            params.student_attended = student_attended
            params.selected_topic = selected_topic
            params.topic_covered_name = (topic_name) ? topic_name : ""
            params.selected_topic_change = (selected_topic_change) ? selected_topic_change : ""
            params.topic_change_reason_text = (topic_change_reason_text) ? topic_change_reason_text : ""
        }

        if (selected_status == "canceled") {
            params.rescheduled_option = "true"
            params.selected_cancel_option = "cancel_this_class"

            let rescheduled_status = ""
            let reschedule_rate = 0

            if (selected_rescheduled_reason) {
                if (selected_rescheduled_reason == "Network issues Trainer") {
                    rescheduled_status = "trainer_skip"
                    reschedule_rate = 0.5
                }

                if (selected_rescheduled_reason == "Personal Reason") {
                    if (call.slot > now) {
                        rescheduled_status = "trainer_skip"
                        eschedule_rate = 0.5
                    }
                    if (call.slot < now) {
                        rescheduled_status = "trainer_no_show"
                        reschedule_rate = 0.5
                    }
                }

                if (selected_rescheduled_reason == "Medical reason") {
                    if (call.slot > now) {
                        rescheduled_status = "trainer_skip"
                        reschedule_rate = 0.5
                    }
                    if (call.slot < now) {
                        rescheduled_status = "trainer_no_show"
                        reschedule_rate = 0.5
                    }
                }
            }

            params.rescheduled_status = rescheduled_status
            params.reschedule_rate = reschedule_rate
            params.rescheduled_reason = selected_rescheduled_reason
            params.notification = (notification) ? notification : 'no';
        }

        if (selected_status == "reschedule") {
            let new_reschedule_status = ""
            let rescheduled_status = ""
            let reschedule_rate = 0
            if (selected_rescheduled_reason) {
                if (rescheduled_by == "trainee") {
                    if (selected_rescheduled_reason == "Network issues Trainee") {
                        new_reschedule_status = "trainee_skip"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "No show") {
                        new_reschedule_status = "trainee_no_show"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "Prior Info") {
                        new_reschedule_status = "trainee_skip"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "Curriculum Issues") {
                        new_reschedule_status = "other_reschedule"
                        reschedule_rate = 0.5
                    }
                }

                if (rescheduled_by == "trainer") {
                    if (selected_rescheduled_reason == "No show") {
                        new_reschedule_status = "trainer_no_show"
                        reschedule_rate = 0
                    }

                    if (selected_rescheduled_reason == "Prior Info") {
                        new_reschedule_status = "trainer_skip"
                        reschedule_rate = 0
                    }

                    if (selected_rescheduled_reason == "Network issues Trainer") {
                        new_reschedule_status = "trainer_skip"
                        reschedule_rate = 0.5
                    }

                    if (selected_rescheduled_reason == "Personal Reason") {
                        if (call.slot > now) {
                            new_reschedule_status = "trainer_skip"
                            reschedule_rate = 0.5
                        }
                        if (call.slot < now) {
                            new_reschedule_status = "trainer_no_show"
                            reschedule_rate = 0.5
                        }
                    }

                    if (selected_rescheduled_reason == "Medical reason") {
                        if (call.slot > now) {
                            new_reschedule_status = "trainer_skip"
                            reschedule_rate = 0.5
                        }
                        if (call.slot < now) {
                            new_reschedule_status = "trainer_no_show"
                            reschedule_rate = 0.5
                        }
                    }
                }
            }

            if (rescheduled_by == "trainee" && selected_rescheduled_reason == "No show") {
                params.status_slug = "lapsed"
                params.selected_status = "lapsed"
                params.rescheduled_reason = "Trainee No show";
                params.notification = (notification) ? notification : 'no';

                params.real_status = "reschedule"
                params.status_change_reason = "automatic"
                params.rescheduled_by = rescheduled_by

            } else {
                params.rescheduled_by = rescheduled_by;
                params.rescheduled_reason = selected_rescheduled_reason;
                params.notification = (notification) ? notification : 'no';
                params.rescheduled_status = new_reschedule_status
                params.reschedule_rate = reschedule_rate
            }
        }

        if (class_type == "WEBINAR" && selected_status == "complete") {
            let ids = []
            for (let index = 0; index < users.length; index++) {
                let item = users[index]
                if (item.checked) {
                    ids.push(item.id)
                }
            }
            params.attended_users = ids
        }

        setSubmitLoading(true)
        await TEACHERS.post(`/webapp/status/update`, params)
            .then((response) => {
                setSubmitLoading(false)
                getCalls()
                setModalOpen(false)

                if (check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit) {
                    Alert.success("Your class has been successfully skipped and scheduled to the next frequency date");
                } else {
                    Alert.success("Status Updated Successfully");
                }

                setCheckSkipRes(null)
                return false
            })
            .catch((error) => {
                console.log(error);
                setSubmitLoading(false)
                Alert.alert("Something Went Wrong.");
                return false

            });

    };

    return (
        <Modal visible={modalOpen} setVisible={setModalOpen} maxHeight={420}>
            {!limit_checked && <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    {
                        <Text style={tailwind`font-bold text-[16px]`}>Update Status</Text>
                    }
                    <AntDesign
                        onPress={() => handleModalClose()}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>

                {/* {
                    call.slot < moment().subtract(24, "hours").format('x') &&
                    < View style={tailwind`items-center`}>
                        <Text style={tailwind`p-2 text-justify`}>
                            Sorry, the window to update the class status has expired. Please write an email to support@multibhashi.com for updating class status with subject line "not able to update dashboard" with below details:
                            Trainee/webinar name, Time, Date, Status, In case of reschedule reason of reschedule, In case of complete , Topic covered, Number of student joined ( in case of webinar)
                        </Text>
                    </View>
                } */}

                {
                    // call.slot > moment().subtract(24, "hours").format('x') &&
                    <>

                        <View style={styles.mainContainer}>
                            <View style={styles.inputContainer}>
                                <Text style={styles.labels}>Select Status </Text>
                                <SelectList
                                    setSelected={(val) => setSelectedStatus(val)}
                                    data={call_statuses}
                                    save="value"
                                    boxStyles={tailwind`rounded py-2 px-2`}
                                    value={selected_status}
                                />
                            </View>

                            {selected_status == "complete" && <>
                                {class_type == "WEBINAR" && selected_status == "complete" &&
                                    <View style={styles.inputContainer}>
                                        <Text style={styles.labels}>No. of student attanded </Text>
                                        <TextInput
                                            style={styles.inputStyle}
                                            placeholder={"No. of student attanded"}
                                            value={student_attended}
                                            onChangeText={(item) => setStudentAttended(item)}
                                        />
                                    </View>
                                }

                                {
                                    selected_status == "complete" && <View style={styles.inputContainer}>
                                        <Text style={styles.labels}>Select Topic </Text>
                                        <SelectList
                                            setSelected={(val) => setSelectedTopic(val)}
                                            data={topics}
                                            save="value"
                                            boxStyles={tailwind`rounded py-2 px-2`}
                                            value={selected_topic}
                                        />
                                    </View>
                                }


                                {
                                    selected_status == "complete" && selected_topic == "Others" && <View style={styles.inputContainer}>
                                        <Text style={styles.labels}>Enter Topic Name </Text>
                                        <TextInput
                                            style={[styles.textarea]}
                                            multiline={true}
                                            numberOfLines={6}
                                            placeholder="Type Topic Name..."
                                            value={topic_name}
                                            onChangeText={setTopicName}
                                        />
                                    </View>
                                }

                                {
                                    selected_status == "complete" && selected_topic && ((class_type == "WEBINAR" && selected_topic != call.topic) || (class_type == "SESSION" && selected_topic != call.topic_name)) && <View style={styles.inputContainer}>
                                        <Text style={styles.labels}>Reason for change of topic </Text>
                                        <SelectList
                                            setSelected={(val) => setSelectedTopicChange(val)}
                                            data={topic_change_reasons}
                                            save="value"
                                            boxStyles={tailwind`rounded py-2 px-2`}
                                            value={selected_topic_change}
                                        />
                                    </View>
                                }

                                {
                                    selected_status == "complete" && selected_topic_change == "Others" && <View style={styles.inputContainer}>
                                        <Text style={styles.labels}>Write reason to change topic</Text>
                                        <TextInput
                                            style={[styles.textarea]}
                                            multiline={true}
                                            numberOfLines={6}
                                            placeholder="Write reason to change topic"
                                            value={topic_change_reason_text}
                                            onChangeText={setTopicChangeReasonText}
                                        />
                                    </View>
                                }



                                {class_type == "WEBINAR" && <View style={[tailwind` my-4 w-full `]}>
                                    <View style={[tailwind`flex-row justify-between items-center`]}>
                                        <Text style={{ fontSize: 16 }}>Mark Attendance</Text>
                                        <TouchableOpacity
                                            style={tailwind`flex-row items-center`}
                                            onPress={toggleSelectAll}
                                        >
                                            <View style={[tailwind`w-4 h-4 border rounded`, allChecked && tailwind`bg-gray-800`]} />
                                            <Text style={tailwind`ml-2`}>Select All</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={tailwind`mt-5`}>
                                        <FlatList
                                            data={users}
                                            keyExtractor={(item) => item.id.toString()}
                                            numColumns={2}
                                            renderItem={({ item }) => (
                                                <View style={tailwind`flex-row items-center mb-2 w-1/2`}>
                                                    <TouchableOpacity
                                                        onPress={() => toggleCheckbox(item.id)}
                                                        style={tailwind`flex-row items-center`}
                                                    >
                                                        <View style={[tailwind`w-4 h-4 border rounded`, item.checked && tailwind`bg-gray-800`]} />
                                                        <Text style={tailwind`ml-2`}>{item.name}</Text>
                                                    </TouchableOpacity>
                                                </View>
                                            )}
                                        />
                                    </View>
                                </View>
                                }

                            </>
                            }

                            {(selected_status == "canceled" || selected_status == "reschedule") &&
                                <>
                                    {
                                        selected_status == "reschedule" &&
                                        <View style={styles.inputContainer}>

                                            <View
                                                style={[
                                                    tailwind``,
                                                    { flexDirection: "row", alignItems: "center" },
                                                ]}
                                            >
                                                <Text style={styles.labels}>Rescheduled By </Text>
                                                <RadioButton
                                                    value={"trainer"}
                                                    status={
                                                        rescheduled_by === "trainer"
                                                            ? "checked"
                                                            : "unchecked"
                                                    }
                                                    onPress={() => handleRescheduledBySelect("trainer")}

                                                />
                                                <Text style={[tailwind`p-1`]}>Trainer</Text>

                                                {call.slot < moment().subtract(24, "hours").format('x') && <>< RadioButton
                                                    value={"trainee"}
                                                    status={
                                                        rescheduled_by === "trainee"
                                                            ? "checked"
                                                            : "unchecked"
                                                    }
                                                    onPress={() => handleRescheduledBySelect("trainee")}
                                                />
                                                    <Text style={[tailwind`p-1`]}>Trainee</Text>
                                                </>
                                                }
                                            </View>


                                        </View>
                                    }
                                    {
                                        selected_status == "reschedule" &&
                                        <View style={styles.inputContainer}>
                                            <Text style={styles.labels}>Reason </Text>
                                            {/* <SelectList
                                            setSelected={(val) => setSelectedRescheduledReason(val)}
                                            data={ses_rescheduled_reasons}
                                            save="value"
                                            boxStyles={tailwind`rounded py-2 px-2`}
                                            value={selected_rescheduled_reason}
                                        /> */}

                                            <SelectList
                                                key={rescheduled_by}
                                                setSelected={(val) => setSelectedRescheduledReason(val)}
                                                data={ses_rescheduled_reasons}
                                                save="value"
                                                boxStyles={tailwind`rounded py-2 px-2`}
                                                value={selected_rescheduled_reason}
                                            />
                                        </View>
                                    }
                                    {
                                        selected_status == "canceled" &&
                                        <View style={styles.inputContainer}>
                                            <Text style={styles.labels}>Reason </Text>
                                            <SelectList
                                                setSelected={(val) => setSelectedRescheduledReason(val)}
                                                data={web_rescheduled_reasons}
                                                save="value"
                                                boxStyles={tailwind`rounded py-2 px-2`}
                                                value={selected_rescheduled_reason}
                                            />
                                        </View>
                                    }


                                </>
                            }

                            {
                                < View style={styles.inputContainer}>
                                    <Text style={styles.labels}>Remarks</Text>
                                    <TextInput
                                        style={[styles.textarea]}
                                        multiline={true}
                                        numberOfLines={6}
                                        placeholder="Write some remarks"
                                        value={remarks}
                                        onChangeText={setRemarks}
                                    />
                                </View>
                            }

                            {(selected_status == "canceled" || selected_status == "reschedule") &&
                                <>
                                    <View style={styles.inputContainer}>

                                        <View
                                            style={[
                                                tailwind``,
                                                { flexDirection: "row", alignItems: "center" },
                                            ]}
                                        >
                                            <Text style={styles.labels}>Do you want to send Email/SMS ?</Text>
                                            <RadioButton
                                                value={"yes"}
                                                status={
                                                    notification === "yes"
                                                        ? "checked"
                                                        : "unchecked"
                                                }
                                                onPress={() => setNotification("yes")}

                                            />
                                            <Text style={[tailwind`p-1`]}>Yes</Text>

                                            <RadioButton
                                                value={"no"}
                                                status={
                                                    notification === "no"
                                                        ? "checked"
                                                        : "unchecked"
                                                }
                                                onPress={() => setNotification("no")}
                                            />
                                            <Text style={[tailwind`p-1`]}>No</Text>
                                        </View>


                                    </View>
                                </>

                            }

                            {!submit_loading &&
                                <>
                                    {selected_status != "reschedule" && <TouchableOpacity
                                        style={[
                                            styles.buttonStyle,
                                            { backgroundColor: selected_status ? "#22c55e" : "#6b7280" },
                                        ]}
                                        onPress={submit}
                                    >
                                        <Text style={styles.buttonText}> Update </Text>
                                    </TouchableOpacity>
                                    }

                                    {selected_status == "reschedule" && <TouchableOpacity
                                        style={[
                                            styles.buttonStyle,
                                            { backgroundColor: selected_status ? "#22c55e" : "#6b7280" },
                                        ]}
                                        onPress={checkSkipLimit}
                                    >
                                        <Text style={styles.buttonText}> Update </Text>
                                    </TouchableOpacity>
                                    }
                                </>
                            }

                            {submit_loading && (
                                <TouchableOpacity
                                    style={[styles.buttonStyle, { backgroundColor: "#22c55e" }]}
                                    onPress={null}
                                >
                                    <Text style={styles.buttonText}> Loading </Text>
                                </TouchableOpacity>
                            )}
                        </View>



                    </>

                }

            </View>
            }

            {limit_checked &&
                <View>

                    {
                        class_rescheduled &&
                        <>
                            <Text style={tailwind`font-semibold text-[16px]`}>
                                Class Rescheduled!
                            </Text>

                            <Text style={tailwind`mt-5`}>
                                Your class has been rescheduled and rescheduled to a later date
                            </Text>

                            <View style={tailwind`flex flex-row w-full justify-end mt-3`}>
                                <TouchableOpacity
                                    style={tailwind`bg-white ml-5 rounded-lg`}
                                    onPress={() => {
                                        setModalOpen(false)
                                        return setClassRescheduled(false);
                                    }}
                                >
                                    <Text style={tailwind`text-[#2296F3]`}>Close</Text>
                                </TouchableOpacity>
                            </View>
                        </>
                    }
                    {!class_rescheduled && <View>
                        {
                            !skip_loading1 && <>
                                <View>
                                    {check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit &&
                                        < Text style={tailwind`mt-5`}>
                                            You have a skip limit of {check_skip_res.skip_limit}, you have consumed  {check_skip_res.used_skip}, and your remaining skip limit is {check_skip_res.skip_limit - check_skip_res.used_skip}. Are you sure you want to skip this class?.
                                        </Text>
                                    }

                                    {check_skip_res && check_skip_res.used_skip >= check_skip_res.skip_limit &&
                                        < Text style={tailwind`mt-5`}>
                                            Your skip limit has been exhausted. Out of {check_skip_res.skip_limit}, you have consumed {check_skip_res.used_skip}. If you want help, please drop an email to support@multibhashi.com with subject line “Skip limit Exhausted- Unable to reschedule.
                                        </Text>
                                    }
                                </View>

                                <View style={tailwind`flex flex-row w-full justify-end mt-3`}>
                                    {skip_loading ? (
                                        < View style={tailwind`flex flex-row w-full justify-center mt-3`}>
                                            <Text><ActivityIndicator size="small" color="#51aadd" /></Text>
                                        </View>
                                    ) : (
                                        <>
                                            {check_skip_res && check_skip_res.used_skip < check_skip_res.skip_limit &&
                                                <TouchableOpacity
                                                    style={tailwind`bg-white rounded-lg`}
                                                    onPress={() => handleRescheduleConfirm("yes")}
                                                >
                                                    <Text style={tailwind`text-[#2296F3]`}>Yes</Text>
                                                </TouchableOpacity>
                                            }
                                            <TouchableOpacity
                                                style={tailwind`bg-white ml-5 rounded-lg`}
                                                onPress={() => handleRescheduleConfirm("no")}
                                            >
                                                <Text style={tailwind`text-[#2296F3]`}>No</Text>
                                            </TouchableOpacity>
                                        </>
                                    )}
                                </View>
                            </>
                        }
                        {
                            skip_loading1 &&
                            < View style={tailwind`flex flex-row w-full justify-center mt-3`}>
                                <Text><ActivityIndicator size="small" color="#51aadd" /></Text>
                            </View>

                        }

                    </View>
                    }
                </View>
            }
        </Modal >
    );
};

export default UpdateStatusModal;

const styles = StyleSheet.create({
    inputContainer: {
        marginTop: 10,
    },
    labels: {
        fontWeight: "bold",
        // fontSize: 15,
        color: "#7d7d7d",
        paddingBottom: 5,
        lineHeight: 25,
    },
    inputStyle: {
        borderRadius: 4,
        borderWidth: 1.4,
        // borderBottomWidth: 1.4,
        borderColor: "rgba(0, 0, 0, 0.3)",
        paddingHorizontal: 10,
        paddingVertical: 6,
    },
    textarea: {
        width: '100%',
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 8,
        padding: 10,
        fontSize: 16,
        textAlignVertical: 'top', // Aligns text to the top of the input field
        backgroundColor: "#EFEFEF"
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
        marginTop: 10
    },
    buttonText: {
        fontWeight: "bold",
        color: "#fff",
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    checkbox: {
        height: 12,
        width: 12,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: 'gray',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    checked: {
        backgroundColor: 'green',
    },
});
